<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:44:40
-->
<template>
	<div class="activityVerifyDetail">
		<common-title :title="'活动详情'" />
		<div class="activityVerifyDetail-content">
			<div class="common-table">
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">活动ID</span>
						<el-input readonly="readonly" v-model="activityVerifyDetail.id" placeholder="请输入"
							class="common-table-val" />
					</div>
					<div class="common-table-col">
						<span class="common-table-label">活动名称</span>
						<span
							class="common-table-text">{{activityVerifyDetail.activityType == 1 ? '非共建基地活动': '共建基地活动'}}</span>
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">活动名称</span>
						<!-- <el-input readonly="readonly" v-model="activityVerifyDetail.activityName" placeholder="请输入"
							class="common-table-val" /> -->
						<div class="common-table-text">
							<span>{{activityVerifyDetail.activityName}}</span><img v-if="activityVerifyDetail.activityType == '1'" class="common-table-icon" src="@/assets/images/common/A.png" alt="">
							<img v-if="activityVerifyDetail.activityType == '3'" class="common-table-icon" src="@/assets/images/common/B.png" alt="">
						</div>
					</div>
					<div class="common-table-col">
						<span class="common-table-label">组织方</span>
						<el-input readonly="readonly" v-model="activityVerifyDetail.organizer" placeholder="请输入"
							class="common-table-val" />
					</div>
				</div>
				<div v-if="activityVerifyDetail.starTime&&activityVerifyDetail.endTime" class="">
					<div class="common-table-row">
						<div class="common-table-col">
							<span class="common-table-label">活动时间</span>
							<div class="common-table-text">
								<div class="">
									<span
										class="common-table-date">{{dateFormat(activityVerifyDetail.starTime)}} 至 {{dateFormat(activityVerifyDetail.endTime)}}</span>
								</div>
							</div>
						</div>
						<div class="common-table-col">
							<span class="common-table-label">活动地址</span>
							<div class="common-table-text">{{activityVerifyDetail.address}}</div>
						</div>
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">活动详情</span>
						<quill-editor :options="editorOption" class="common-table-val" :style="{'height': '400px'}"
							v-model="activityVerifyDetail.activityDetails" @focus="editorFocus($event)">
						</quill-editor>
						<!-- <el-input readonly="readonly" type="textarea" v-model="activityVerifyDetail.plan"
							placeholder="请输入" class="common-table-val" /> -->
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">工时认定</span>
						<div class="common-row-time" style="border-top: 0;">
							<div class="row-time-val">
								<div class="time-val-item">
									<span class="val-item-label">核定活动人数上限</span>
									<el-input v-model="activityVerifyDetail.maxNumPeople" class="val-item-ipt"
										placeholder="请输入" />
								</div>
								<div class="time-val-item">
									<span class="val-item-label">核定单人工时数上限</span>
									<el-input v-model="activityVerifyDetail.maxOneWorkingHours" class="val-item-ipt"
										placeholder="请输入" />
								</div>
								<div class="time-val-item">
									<span class="val-item-label">核定活动总工时数上限</span>
									<el-input v-model="activityVerifyDetail.maxWorkingHours" class="val-item-ipt"
										placeholder="请输入" />
								</div>
							</div>
							<div class="row-time-mark">
								<span class="time-mark-label">注：</span>
								<div class="time-mark-content">
									<span>核定活动人数上限指该活动的可参加的总人数的上限。（限整数）</span>
									<span>核定核定单人工时数上限指该活动单人可获得的工时数上限。（限整数/一位小数）</span>
									<span>核定活动总工时数上限指该活动的最终生成工时表的所有志愿者工时数之和。（限整数/一位小数）</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="common-table-row">
					<div class="common-table-col">
						<span class="common-table-label">审批操作</span>
						<div class="common-table-verify">
							<div class="table-verify-item">
								<span class="verify-item-label">是否通过</span>
								<el-radio-group v-model="activityVerifyDetail.activityStatus">
									<el-radio :label="2">通过</el-radio>
									<el-radio :label="0">不通过</el-radio>
								</el-radio-group>
							</div>
							<div v-if="verifyAgain != 'Yes'" class="table-verify-item">
								<span class="verify-item-label">审核意见</span>
								<el-input type="textarea" v-model="activityVerifyDetail.auditOpinion" placeholder="请输入"
									class="verify-item-ipt" />
							</div>
							<div v-if="verifyAgain == 'Yes'" class="table-verify-item">
								<span class="verify-item-label">审核意见</span>
								<el-input type="textarea" v-model="activityVerifyDetail.twoAuditReason" placeholder="请输入"
									class="verify-item-ipt" />
							</div>
							<div class="table-verify-item">
								<span class="verify-item-label">扣分选项</span>
								<div class="flex-column">
									<el-checkbox-group @change="deductionChange" v-model="deductionList">
										<el-checkbox v-for="(item, index) in cutRecordsList" :key="index"
											:disabled="item.disabled" :label="item.label">{{item.label}}</el-checkbox>
									</el-checkbox-group>
									<!-- <el-radio-group v-model="activityVerifyDetail.deduction">
										<el-radio v-for="(item, index) in cutRecordsList" :key="index"
											:label="item.value">{{item.label}}</el-radio>
									</el-radio-group> -->
									<div class="flex-item" style="margin-top: 16px;">
										<el-checkbox-group @change="deductionChange" v-model="deductionList">
											<el-checkbox :disabled="otherDisable" label="其他">其他</el-checkbox>
										</el-checkbox-group>
										<el-input :disabled="otherDisable" style="margin-left: 14px;"
											v-model="activityVerifyDetail.reasonsDetails" class="val-item-ipt"
											placeholder="请输入" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="activityVerifyDetail-btn">
				<el-button v-preventReClick @click="submitVerify" type="primary">确定</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getActivityDetail,
		verifyActivity,
	} from "@/api/activity";
	import {
		getEnumKeyList
	} from "@/api/index.js";
	export default {
		data() {
			return {
				activityVerifyDetail: {
					reasonsDetails:"123"
				},
				activityId: "",
				cutRecordsList: [],
				deductionList: [],
				otherDisable: false,
				verifyAgain: "",
				editorOption: {
					placeholder: "暂无内容......",
				}
			};
		},
		mounted() {
			this.activityId = this.$route.query.activityId
			this.verifyAgain = this.$route.query.verifyAgain
			this.getDeductionList()
			this.activityDetailFn()
		},
		beforeRouteLeave(to,from,next){
			to.meta.isAlive = true
			next()
		},
		methods: {
			deductionChange() {
				console.log("deductionChange", this.deductionList)
				if (this.deductionList.length > 0) {
					if (this.deductionList.indexOf('无') > -1) {
						this.otherDisable = true
						this.activityVerifyDetail.reasonsDetails  = ""
						this.cutRecordsList.forEach(item => {
							if (item.label != '无') {
								item.disabled = true
							}
						})
					}
					if (this.deductionList.indexOf('无') == -1) {
						this.otherDisable = true
						this.cutRecordsList.forEach(item => {
							if (item.label == '无') {
								item.disabled = true
							}
						})
					}
					if (this.deductionList.indexOf('其他') > -1) {
						this.otherDisable = false
						this.cutRecordsList.forEach(item => {
							item.disabled = true
						})
					}
					if (this.deductionList.indexOf('其他') === -1) {
						// console.log('reasonsDetails')
						this.otherDisable = true
						this.cutRecordsList.forEach(item => {
							if (item.label == '其他') {
								item.disabled = true
							}
						})
					}
				} else {
					// console.log('reasonsDetails1')
					this.otherDisable = false
					// this.activityVerifyDetail.reasonsDetails  = ""
					this.cutRecordsList.forEach(item => {
						item.disabled = false
					})
				}

			},
			getDeductionList() {
				getEnumKeyList({
					enumKey: "AUDIT_REASONS"
				}).then((res) => {
					this.cutRecordsList = res.data.map(item => {
						return {
							label: item.value,
							value: item.key,
							disabled: false
						}
					})
					this.cutRecordsList = this.cutRecordsList.filter(item => item.value != 9)
				})
			},
			dateFormat(intDate) {
				if (intDate) {
					return intDate.replace(" 00:00:00", "")
				}
			},
			editorFocus(e) {
				e.enable(false);
			},
			// 获取活动详情
			activityDetailFn() {
				getActivityDetail(this.activityId, {
					id: this.activityId
				}).then((res) => {
					if (res.code == 0) {
						this.activityVerifyDetail = res.data
						// console.log("verifyAgain", this.verifyAgain)
						if(this.verifyAgain != 'Yes') {
							this.activityVerifyDetail.activityStatus = 2
						}
						// this.activityVerifyDetail.activityStatus = 2
						// if(this.verifyAgain != 'Yes') {
						// 	if (!res.data.deduction) {
						// 		this.deductionList = []
						// 		this.$set(this.activityVerifyDetail, "deduction", [])
						// 	} else {
						// 		this.deductionList = res.data.deduction.split(",")
						// 	}
						// } else {
						// 	this.deductionList = []
						// 	this.$set(this.activityVerifyDetail, "deduction", [])
						// 	this.activityVerifyDetail.reasonsDetails = ""
						// }
						if (!res.data.deduction) {
							this.deductionList = []
							this.$set(this.activityVerifyDetail, "deduction", [])
						} else {
							this.deductionList = res.data.deduction.split(",")
						}
						this.deductionChange()
					}
				})
			},
			workingHoursCheck(type, intNum) {
				if(intNum) {
					let regx1 = /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/;
					let regx2 = /^[1-9]+[0-9]*$/;
					if (type == 'point') {
						if (regx1.test(intNum)) {
							let pointIndex = intNum.lastIndexOf('.')
							if (pointIndex != -1) {
								let regx3 = /^\d+(\.\d{1,1})?$/
								if (regx3.test(intNum)) {
									let pointNum = intNum.slice(pointIndex + 1, intNum.length)
									console.log("pointNum", pointIndex, pointNum)
									if ((pointNum * 1) == 5) {
										return true
									} else {
										return false
									}
								} else {
									return false
								}
							} else {
								return true
							}
						} else {
							return false
						}
					} else {
						if (regx2.test(intNum)) { 
							return true
						} else {
							return false
						}
					}
				} else {
					return false
				}
			},
			submitVerify() {
				let verifyForm = JSON.parse(JSON.stringify(this.activityVerifyDetail))
				if (verifyForm.activityStatus != 0) {
					if (!this.workingHoursCheck('integer', verifyForm.maxNumPeople + '')) {
						this.$message.error("核定活动人数上限数值不规范~")
						return
					}
					if (!this.workingHoursCheck('point', verifyForm.maxOneWorkingHours + '')) {
						this.$message.error("核定单人工时数上限不规范~")
						return
					}
					if (!this.workingHoursCheck('point', verifyForm.maxWorkingHours + '')) {
						this.$message.error("核定活动总工时数上限不规范~")
						return
					}
				}
				if(this.deductionList.length > 0) {
					verifyForm.deduction = this.deductionList
					if (this.deductionList.indexOf('其他') == -1) {
						verifyForm.reasonsDetails = ""
					} else if (this.deductionList.indexOf('其他') != -1) {
						if(!verifyForm.reasonsDetails) {
							this.$message.error("请填写其他扣分原因~")
							return
						}
					}
					verifyForm.deduction = this.deductionList.join(",")
				} else {
					this.$message.error("请选择扣分选项~")
					return
				}
				if(this.verifyAgain == 'Yes') {
					this.$set(verifyForm, "twoAudit", true)
				}
				console.log("submitVerify", verifyForm)
				verifyActivity(verifyForm).then((res) => {
					if(res.code == 0) {
						this.$message.success("一般活动审核成功~")
						this.$router.go(-1)
					}
				})
			},
		}
	};
</script>

<style lang="scss" scoped>
	/deep/.el-input__inner {
		height: 36px;
		border-radius: 0px 2px 2px 0px;
		border: 0;
	}

	/deep/.common-table-row .el-textarea__inner {
		resize: none;
		height: 400px;
		border: 0;
	}

	/deep/.table-verify-item .el-textarea__inner {
		resize: none;
		height: 68px;
		border: 1px solid #EDF1F7;
	}

	.flex-item {
		display: flex;
		align-items: center;
	}

	.flex-column {
		display: flex;
		flex-direction: column;
	}

	.common-table-date {
		margin-right: 8px;
	}
	
	.common-table-icon {
		width: 28px;
		height: 15px;
		margin-left: 8px;
	}

	.activityVerifyDetail {
		.activityVerifyDetail-content {
			padding: 0 20px 20px 20px;

			.val-item-ipt {
				border: 1px solid #EDF1F7;
			}

			.activityVerifyDetail-btn {
				margin: 20px 0 0 100px;
			}
		}
	}
</style>
